.incorrect {
    color: white;
    background-color: red;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
}

.correct {
    color: white;
    background-color: rgb(78, 201, 119);
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
}
  