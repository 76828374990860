.form-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.simpleButton {
  margin: 10px;
}

.default {
  color: black;
}


.incorrect {
  color: white;
}

.input-row {
  display: flex;
  align-items: center;
}

.input-row > input[type="text"] {
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  margin-right: 5px; /* Adjust margin as needed */
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}