.container {
    flex-direction: column;
    text-align: center;
    color: white;
}

.content {
    color: black;
}

.description {
    margin: 10px;
}

input[type="text"] {
    margin: 15px; /* Adjust margin as needed */
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
  }

p {
    max-width: 500px;
}