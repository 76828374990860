.container {
    flex-direction: column;
    text-align: center;
    color: white;
}

.content {
    color: black;
}

.description {
    margin: 10px;
}

p {
    max-width: 500px;
}